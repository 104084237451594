import { DialogContent, DialogHeader } from "components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import FreelancerPitch from "./components/FreelancerPitch";
import MatchHistory from "./components/MatchHistory";
import ActivityHistory from "./components/ActivityHistory";
import MatchMetadata from "./components/MatchMetadata";
import { Suspense } from "react";
import SkeletonTables from "components/SkeletonTables";
import MatchAnalysisWrapper from "./components/MatchAnalysis";
import { Loader } from "lucide-react";

export default function UserInfoModalV2({
  match,
  user,
  tabDefaultValue = "freelancer-pitch",
}) {
  const { applicationId, freelancerPitch, jobOpportunityId } = match;

  const { username, id } = user;

  return (
    <DialogContent className="min-w-[70vw] lg:min-w-[55vw] w-full lg:">
      <DialogHeader className="font-bold text-2xl">
        User: {username}
      </DialogHeader>

      <Tabs
        activationMode="manual"
        defaultValue={tabDefaultValue}
        className="max-w-full"
      >
        <TabsList className="justify-around w-full">
          <TabsTrigger value="freelancer-pitch">Freelancer Pitch</TabsTrigger>
          <TabsTrigger value="match-history">Match History</TabsTrigger>
          <TabsTrigger value="activity-history">Activity History</TabsTrigger>
          <TabsTrigger value="tags">Tags</TabsTrigger>
          <TabsTrigger value="match-analysis-AI">
            {"Match Analysis (AI)"}
          </TabsTrigger>
        </TabsList>

        <TabsContent value="freelancer-pitch">
          <FreelancerPitch pitch={freelancerPitch} />
        </TabsContent>

        <TabsContent value="match-history">
          <Suspense
            fallback={
              <SkeletonTables
                headers={["Old Value", "New Value", "Changed By", "Date"]}
              />
            }
          >
            <MatchHistory
              applicationId={applicationId}
              jobOpportunityId={jobOpportunityId}
            />
          </Suspense>
        </TabsContent>

        <TabsContent value="activity-history">
          <Suspense
            fallback={
              <SkeletonTables headers={["Action", "Message", "Date"]} />
            }
          >
            <ActivityHistory userId={id} />
          </Suspense>
        </TabsContent>

        <TabsContent value="tags">
          <Suspense fallback={<SkeletonTables headers={["Key", "Value"]} />}>
            <MatchMetadata
              applicationId={match.applicationId}
              jobOpportunityId={match.jobOpportunityId}
            />
          </Suspense>
        </TabsContent>

        <TabsContent value="match-analysis-AI">
          <Suspense
            fallback={
              <div className="flex justify-center items-center w-full min-h-[325px]">
                <Loader />
              </div>
            }
          >
            <MatchAnalysisWrapper
              applicationId={match.applicationId}
              jobOpportunityId={match.jobOpportunityId}
            />
          </Suspense>
        </TabsContent>
      </Tabs>
    </DialogContent>
  );
}
