import Base from "./Base.js";

import { createSharedAssessment, updateUser } from "api/graphql/mutations.js";

import {
  getMe,
  getUserActivityHistory,
  getUserByEmail,
  getUserByUsername,
  listSharedAssessmentsByUser,
  getCognitoGroupsForUser,
  getUserByReferralCode,
  getApplicationsForUser,
} from "./graphql/queries.js";

import { onUserResumeDataExtraction } from "./graphql/subscriptions.js";

export default class UserAPI extends Base {
  update(payload) {
    return this.apiClient.request({ query: updateUser, payload });
  }

  getMe() {
    return this.apiClient.request({ query: getMe });
  }

  getActivityHistory(payload) {
    return this.apiClient.request({ query: getUserActivityHistory, payload });
  }

  getByUsername(payload) {
    return this.apiClient.request({ query: getUserByUsername, payload });
  }

  getByEmail(payload) {
    return this.apiClient.request({ query: getUserByEmail, payload });
  }

  getByReferralCode(payload) {
    return this.apiClient.request({ query: getUserByReferralCode, payload });
  }

  shareAssessmentMutation(payload) {
    return this.apiClient.request({ query: createSharedAssessment, payload });
  }

  getSharedAssessments(payload) {
    return this.apiClient.request({
      query: listSharedAssessmentsByUser,
      payload,
    });
  }

  getCognitoGroupsByUsername(payload) {
    return this.apiClient.request({
      query: getCognitoGroupsForUser,
      payload,
    });
  }

  onUserResumeDataExtraction(payload) {
    return this.apiClient.request({
      query: onUserResumeDataExtraction,
      payload,
    });
  }

  async getAllApplicationsForUser(payload) {
    let applications = [];
    let nextToken = null;

    do {
      const response = await this.apiClient.request({
        query: getApplicationsForUser,
        payload: {
          userId: payload.userId,
          nextToken,
        },
      });

      nextToken = response.data.getApplicationsForUser.nextToken;
      const newApplications = response.data.getApplicationsForUser.items;

      applications.push(...newApplications);
    } while (nextToken);

    return applications;
  }
}
