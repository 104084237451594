import { JOB_OPPORTUNITY_STATUSES, BULLHORN_METADATA_FORMATTED } from "lookup";

export const getCustomerName = (jobOpp = {}) => {
  const organizationName = jobOpp?.organization;
  const customerName =
    jobOpp?.customer?.companyDetails?.name || jobOpp?.customer?.company;

  return organizationName || customerName;
};

export const formatJobStatus = (status) => {
  let txt = status;

  switch (status) {
    case JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL:
      txt = "PENDING";
      break;
    default:
      txt = status;
      break;
  }

  return txt;
};

export const parseBullhornMetadata = (metadata) => {
  const parsed =
    typeof metadata === "string" ? JSON.parse(metadata) : metadata || {};

  const bullhorn = parsed?.["BULLHORN"];

  if (!bullhorn) return [];

  const bullhornMetadata =
    typeof bullhorn === "string" ? JSON.parse(bullhorn) : bullhorn || {};

  // Creates an array of objects with each object containing { key: "key", value: "value" }
  const metadataArr = Object.entries(bullhornMetadata)
    .map(([key, value]) => {
      const keyNoUnderscores = key.replace(/_c$/, "").replace(/_/g, " ").trim();

      // not all need special formatting, most are formatted correctly from regex
      const formattedKey =
        BULLHORN_METADATA_FORMATTED[keyNoUnderscores] || keyNoUnderscores;

      value = formatMetadataValue(value);
      return { key: formattedKey, value };
    })
    .filter(({ value }) => !!value && value.length > 0 && value !== "null");

  return metadataArr;
};

function formatArray(arr, indentLevel = 0) {
  const indent = "  ".repeat(indentLevel);
  return arr
    .map((item) => {
      if (typeof item === "object" && item !== null) {
        return formatMetadataValue(item, indentLevel + 1);
      } else {
        return `${indent}  - ${item}`;
      }
    })
    .join("\n");
}

function formatMetadataValue(data, indentLevel = 0) {
  const indent = "  ".repeat(indentLevel);

  if (Array.isArray(data)) {
    return formatArray(data, indentLevel);
  } else if (typeof data === "object" && data !== null) {
    return Object.entries(data)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${indent}${key}:\n${formatArray(value, indentLevel + 1)}`;
        } else if (typeof value === "object" && value !== null) {
          return `${indent}${key}:\n${formatMetadataValue(
            value,
            indentLevel + 1
          )}`;
        } else {
          return `${indent}${key}: ${value}`;
        }
      })
      .join("\n");
  }

  return `${indent}${data}`;
}
