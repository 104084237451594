import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import SvgIcon from "../SvgIcon";

const SvgButton = ({
  icon,
  onClick,
  className,
  isLoading,
  iconProps,
  disabled,
  tooltip,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        "text-gray-500 fill-gray-500 min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] lg:min-w-[35px] lg:min-h-[35px] lg:max-w-[35px] lg:max-h-[35px] p-[5px] box-border hover:bg-gray-300 transition-all rounded-full flex justify-center items-center relative",
        { "pointer-events-none": isLoading },
        className
      )}
      onClick={onClick}
      disabled={disabled}
      title={tooltip}
    >
      <SvgIcon
        type={icon}
        className="flex justify-center items-center w-full h-full"
        {...iconProps}
      />

      {isLoading && (
        <div className="loader absolute !w-[25px] !h-[25px] !border" />
      )}
    </button>
  );
};

SvgButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  iconProps: PropTypes.object,
};

SvgButton.defaultProps = {
  onClick: () => {},
  className: "",
  isLoading: false,
  iconProps: {},
};

export default SvgButton;
