/**
 * Wrapper over <BullhornMetadata /> component - to fetch latest metadata on a Match record's User
 */
import { useSuspenseQuery } from "@apollo/client";
import { GET_MATCH_METADATA } from "api/graphql/match";
import BullhornMetadata from "components/BullhornMetadata";

export default function MatchMetaData({ applicationId, jobOpportunityId }) {
  const { error, data } = useSuspenseQuery(GET_MATCH_METADATA, {
    variables: {
      applicationId,
      jobOpportunityId,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    return (
      <p>
        An error occurred fetching meta data for the Match. Refresh the page and
        if the error persists, contact Torc Support
      </p>
    );
  }

  return <BullhornMetadata type="table" metadata={data.metadata} />;
}
