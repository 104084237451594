const FeatureFlag = ({ flag, local, value, children }) => {
  if (local && !localStorage.getItem(flag)) {
    return null;
  }

  if (!process.env[`REACT_APP_${flag}`]) {
    return null;
  } else if (value && process.env[`REACT_APP_${flag}`] !== value) {
    return null;
  }

  return children;
};

export default FeatureFlag;
